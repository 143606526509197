@font-face {
font-family: 'cabinetGrotesk';
src: url(/_next/static/media/57042efd954c574e-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: 'cabinetGrotesk Fallback';src: local("Arial");ascent-override: 84.24%;descent-override: 27.11%;line-gap-override: 8.71%;size-adjust: 103.28%
}.__className_c4944d {font-family: 'cabinetGrotesk', 'cabinetGrotesk Fallback'
}.__variable_c4944d {--font-cabinet-grotesk: 'cabinetGrotesk', 'cabinetGrotesk Fallback'
}

@font-face {
font-family: 'nunitoSans';
src: url(/_next/static/media/00cd1bad2c9a4d80-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'nunitoSans';
src: url(/_next/static/media/5254679a567e92e6-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: 'nunitoSans';
src: url(/_next/static/media/fce121f02e861785-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'nunitoSans Fallback';src: local("Arial");ascent-override: 99.49%;descent-override: 34.74%;line-gap-override: 0.00%;size-adjust: 101.62%
}.__className_e73194 {font-family: 'nunitoSans', 'nunitoSans Fallback'
}.__variable_e73194 {--font-nunito-sans: 'nunitoSans', 'nunitoSans Fallback'
}

